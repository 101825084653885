import './assets/main.css'
// import './assets/global.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

// Set the title dynamically on route change
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || import.meta.env.VITE_APP_TITLE || '???'
  next()
})

app.use(router)

app.mount('#app')

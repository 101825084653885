<script setup>
import { computed, onMounted, getCurrentInstance } from 'vue'
import { useRouter, useRoute, RouterLink } from 'vue-router'

const props = defineProps({
  inverted: {
    type: Boolean,
    default: false,
  },
  backgroundColor: {
    type: String,
    default: 'transparent',
  },
  border: {
    type: String,
    default: 'none',
  },
  blurInPx: {
    type: Number,
    default: 0,
  },
  enableSubmenu: {
    type: Boolean,
    default: false,
  },
  rightMenu: {
    type: Array,
    default: () => [],
  },
  showBreadcrumb: {
    type: Boolean,
    default: false,
  },
  enableHamburger: {
    type: Boolean,
    default: true,
  },
  showActive: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()
const route = useRoute()

const instance = getCurrentInstance()
const appNavId = `appNav${instance?.uid || ''}`
const productMenuItemId = `productMenuItem${instance?.uid || ''}`
const customPopupId = `customPopup${instance?.uid || ''}`
const mobileModalId = `mobileModal${instance?.uid || ''}`

const isActive = (path) => {
  return props.showActive && route.path === path
}

const imageSrc = computed(() => {
  return props.inverted
    ? '/images/Memento_horizontallogo_whiteFG_transparentBG.png'
    : '/images/Memento_horizontallogo_gradientFG_transparentBG.png'
})

const currentView = computed(() => {
  return {
    desc: route.meta?.desc || route.name,
    name: route.name,
  }
})

const isProductSelected = computed(() => {
  return isActive('/dfm-protocol') || isActive('/zk-chain')
})

onMounted(() => {
  $(`#${productMenuItemId}`).popup({
    on: 'hover',
    popup: `#${customPopupId}`,
    position: 'bottom center',
    variation: 'basic',
    hoverable: true,
    delay: {
      show: 100,
      hide: 700,
    },
  })
})

function toggleModal() {
  $(`#${mobileModalId}`).modal('show')
}

function goTo(path) {
  router.push(path)
  $(`#${mobileModalId}`).modal('hide')
}
</script>

<template>
  <div :id="appNavId" :style="{ 'border-bottom': border }">
    <!-- Computer only -->
    <table
      class="_computerOnly"
      style="width: 100%; height: 100%; border-spacing: 0"
      :style="{
        'background-color': backgroundColor,
        'backdrop-filter': `blur(${blurInPx}px)`,
      }"
    >
      <tbody>
        <tr>
          <td style="min-width: 5vw"></td>
          <td style="width: 97%">
            <a href="/">
              <img
                class="ui image"
                :src="imageSrc"
                style="min-width: 200px; width: 200px; height: auto"
              />
            </a>
          </td>
          <td style="min-width: 120px">
            <RouterLink
              class="_menuItem"
              :class="{ _inverted: props.inverted, active: isActive('/about-us') }"
              to="/about-us"
            >
              About Us
            </RouterLink>
          </td>
          <td style="min-width: 150px">
            <a
              :id="productMenuItemId"
              class="_menuItem"
              :class="{ _inverted: props.inverted, active: isProductSelected }"
            >
              Products
              <i class="angle down icon" style="margin: 0 0 0 0.5rem" />
            </a>
            <div
              :id="`${customPopupId}`"
              class="ui custom popup"
              style="
                padding: 0.8rem 0;
                margin-left: 5rem;
                width: 300px;
                border-radius: 0;
                box-shadow: none;
                border: none;
              "
              :style="{
                'background-color': props.inverted
                  ? 'rgba(255, 255, 255, 0.05)'
                  : 'rgba(0, 0, 0, 0.05)',
              }"
            >
              <table style="width: 300px; border-spacing: 0">
                <tbody>
                  <tr>
                    <td style="width: 50%">
                      <RouterLink
                        class="_menuItem"
                        :class="{ _inverted: props.inverted, active: isActive('/dfm-protocol') }"
                        to="/dfm-protocol"
                      >
                        DFM Protocol
                      </RouterLink>
                    </td>
                    <td style="width: 50%">
                      <RouterLink
                        class="_menuItem"
                        :class="{ _inverted: props.inverted, active: isActive('/zk-chain') }"
                        to="/zk-chain"
                      >
                        ZKchain
                      </RouterLink>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
          <td style="min-width: 120px">
            <RouterLink
              class="_menuItem"
              :class="{ _inverted: props.inverted, active: isActive('/services') }"
              to="/services"
            >
              Services
            </RouterLink>
          </td>
          <td style="min-width: 120px">
            <RouterLink
              class="_menuItem"
              :class="{ _inverted: props.inverted, active: isActive('/contacts') }"
              to="/contacts"
            >
              Contacts
            </RouterLink>
          </td>
          <td style="width: 1%">
            <a href="https://twitter.com/Memento_Bc" target="Memento-twitter">
              <div class="x-image" :class="{ inverted: props.inverted }"></div>
            </a>
          </td>
          <td style="width: 1%">
            <a href="https://sg.linkedin.com/company/memento-blockchain" target="Memento-linkedin">
              <div class="linkedin-image" :class="{ inverted: props.inverted }"></div>
            </a>
          </td>
          <td style="width: 1%">
            <a href="https://t.me/mementobc" target="Memento-telegram">
              <div class="telegram-image" :class="{ inverted: props.inverted }"></div>
            </a>
          </td>
          <td style="width: 1%">
            <a href="https://medium.com/@memento-bc" target="Memento-medium">
              <div class="medium-image" :class="{ inverted: props.inverted }"></div>
            </a>
          </td>
          <td style="min-width: 5vw"></td>
        </tr>

        <tr v-if="enableSubmenu">
          <td colspan="9">
            <div
              id="submenu"
              class="ui horizontal segments container"
              xstyle="background-color: green"
            >
              <div class="ui fitted basic segment _biggerScreenOnly">
                <div
                  v-show="showBreadcrumb"
                  class="ui breadcrumb"
                  :class="{ inverted: props.inverted }"
                  style="margin-top: 0.5rem"
                >
                  <RouterLink class="section" to="/">Home</RouterLink>
                  <div class="divider">/</div>
                  <div class="active section">{{ currentView.desc }}</div>
                </div>
              </div>
              <div
                id="rightMenu"
                v-if="rightMenu.length"
                class="ui fitted basic segment right aligned"
              >
                <a
                  v-for="menu in rightMenu"
                  :key="menu.label"
                  :class="{ 'ui small button _blurredGlassButton2': menu.type === 'button' }"
                  :href="menu.href"
                  :target="menu.target"
                  :style="{ padding: menu.type === 'button' ? '0.8em 1.6875em 0.8em' : undefined }"
                >
                  {{ menu.label }}
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Mobile only -->
    <table
      class="_mobileOnly"
      style="width: 100%; height: 100%; border-spacing: 0; padding: 0 16px"
      :style="{
        'background-color': backgroundColor,
        'backdrop-filter': `blur(${blurInPx}px)`,
      }"
      :class="{ inverted: props.inverted }"
    >
      <tbody>
        <tr>
          <!-- <td style="min-width: 5vw"></td> -->
          <td style="width: 99%">
            <img
              class="ui image"
              :src="imageSrc"
              style="min-width: 200px; width: 200px; height: auto"
              @click="goTo('/')"
            />
          </td>
          <td style="width: 1%">
            <i
              id="hamburgerButton"
              class="hamburger icon"
              :class="{ inverted: props.inverted }"
              style="margin-right: 16px !important"
              @click="toggleModal()"
            >
            </i>
          </td>
          <!-- <td style="min-width: 5vw"></td> -->
        </tr>
      </tbody>
    </table>

    <!-- The modal for the mobile menu -->
    <div :id="mobileModalId" class="ui overlay fullscreen modal">
      <i class="close icon"></i>
      <div
        class="ui vertical menu"
        style="
          width: 100%;
          height: calc(100vh - 16px) !important;
          border-bottom-left-radius: 32px !important;
          border-bottom-right-radius: 32px !important;
        "
      >
        <div class="header item" @click="goTo('/')">
          <img
            class="ui image"
            src="/images/Memento_horizontallogo_gradientFG_transparentBG.png"
            style="height: 34px; width: auto"
          />
        </div>

        <div class="item _menuItem" @click="goTo('about-us')">About Us</div>
        <div class="item">
          <div class="header">PRODUCTS</div>
          <div class="item _menuItem" @click="goTo('dfm-protocol')">DFM Protocol</div>
          <div class="item _menuItem" @click="goTo('zk-chain')">ZKchain</div>
        </div>
        <div class="item _menuItem" @click="goTo('services')">Services</div>
        <div class="item _menuItem" @click="goTo('contacts')">Contacts</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menu,
.menu.inverted {
  background-color: transparent !important;
}

table {
  transition-duration: 0.3s;
  transition-property: color, background-color, backdrop-filter;
}

._menuItem {
  color: #13151b;
  font-weight: 400;
  line-height: 1;
}

._menuItem._inverted {
  color: rgba(255, 255, 255, 0.9) !important;
}

._menuItem:hover {
  color: #4fbaff !important;
}

._menuTD {
  xbackground-color: rgba(255, 255, 255, 0.1) !important;
}

#productSubMenu,
#mobileSubMenu {
  background-color: transparent;
  border-radius: 4px;
}

._blurredGlass {
  backdrop-filter: blur(12px) !important;
  background: rgba(200, 200, 200, 0.3) !important;
  color: #13151b !important;
}

.inverted ._blurredGlass {
  color: white !important;
}

._blurredGlassButton2 {
  backdrop-filter: blur(12px) !important;
  background: rgba(19, 21, 27, 0.08);
  color: #13151b !important;
}

._blurredGlassButton2:hover {
  background: rgba(19, 21, 27, 0.16);
}

.x-image {
  width: 20px;
  height: 20px;
  background-image: url('/images/socials/x.png');
  background-size: cover;
}

.x-image.inverted {
  background-image: url('/images/socials/x-inverted.png');
}

.x-image:hover {
  background-image: url('/images/socials/x-hover.png');
}

.linkedin-image {
  width: 20px;
  height: 20px;
  background-image: url('/images/socials/linkedin.png');
  background-size: cover;
}

.linkedin-image.inverted {
  background-image: url('/images/socials/linkedin-inverted.png');
}

.linkedin-image:hover {
  background-image: url('/images/socials/linkedin-hover.png');
}

.telegram-image {
  width: 20px;
  height: 20px;
  background-image: url('/images/socials/telegram.png');
  background-size: cover;
}

.telegram-image.inverted {
  background-image: url('/images/socials/telegram-inverted.png');
}

.telegram-image:hover {
  background-image: url('/images/socials/telegram-hover.png');
}

.medium-image {
  width: 20px;
  height: 20px;
  background-image: url('/images/socials/medium.png');
  background-size: cover;
}

.medium-image.inverted {
  background-image: url('/images/socials/medium-inverted.png');
}

.medium-image:hover {
  background-image: url('/images/socials/medium-hover.png');
}

td {
  padding: 0 0.5rem;
}

#rightMenu > a {
  color: #13151b;
  margin-right: 1.5rem;
}

#rightMenu > a:last-child {
  margin-right: 0;
}

@media screen and (min-width: 700px) {
  ._mobileOnly {
    display: none !important;
  }
}

@media screen and (max-width: 699px) {
  ._computerOnly {
    display: none !important;
  }
}

@media screen and (min-width: 1020px) {
  ._smallerScreensOnly {
    display: none !important;
  }
}

@media screen and (max-width: 1019px) {
  ._biggerScreenOnly {
    display: none !important;
  }

  #submenu {
    margin-right: 0;
  }

  ._menuTD {
    background-color: transparent !important;
  }
}
</style>

<script setup>
import { computed, onMounted, getCurrentInstance } from 'vue'
import { useRouter, useRoute, RouterLink } from 'vue-router'

const props = defineProps({
  inverted: {
    type: Boolean,
    default: false,
  },
  showActive: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()
const route = useRoute()

const instance = getCurrentInstance()
const footerId = `footer${instance?.uid || ''}`

const imageSrc = computed(() => {
  return props.inverted
    ? '/images/Memento_horizontallogo_whiteFG_transparentBG.png'
    : '/images/Memento_horizontallogo_gradientFG_transparentBG.png'
})

onMounted(() => {})

function goTo(path) {
  router.push(path)
}
</script>

<template>
  <div :id="footerId" style="margin: 0">
    <!-- Computer only -->
    <table class="_computerOnly" style="width: 100%; height: 100%; border-spacing: 0">
      <tbody>
        <tr style="height: 33%">
          <td style="min-width: 5vw"></td>
          <td style="width: 100%">
            <a href="/">
              <img
                class="ui image"
                :src="imageSrc"
                style="min-width: 200px; width: 200px; height: auto"
              />
            </a>
          </td>
          <td style="min-width: 120px">
            <span class="_menuItem _header" :class="{ _inverted: props.inverted }">Company</span>
          </td>
          <td style="min-width: 175px"></td>
          <td style="min-width: 175px">
            <span class="_menuItem _header" :class="{ _inverted: props.inverted }">Products</span>
          </td>
          <td style="min-width: 175px">
            <span class="_menuItem _header" :class="{ _inverted: props.inverted }">Contact</span>
          </td>
          <td colspan="2">
            <span class="_menuItem _header" :class="{ _inverted: props.inverted }">Social</span>
          </td>
          <td style="min-width: 5vw"></td>
        </tr>

        <tr style="height: 33%">
          <td style="min-width: 5vw"></td>
          <td style="width: 100%"></td>
          <td style="min-width: 120px">
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/about-us">
              About Us
            </RouterLink>
          </td>
          <td style="min-width: 175px">
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/dextf">
              $DEXTF
            </RouterLink>
          </td>
          <td style="min-width: 175px">
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/dfm-protocol">
              DFM Protocol
            </RouterLink>
          </td>
          <td style="min-width: 175px">
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/contacts">
              Email Us
            </RouterLink>
          </td>
          <td style="min-width: 50px">
            <a href="https://twitter.com/Memento_Bc" target="memento-twitter">
              <div class="x-image" :class="{ inverted: props.inverted }"></div>
            </a>
          </td>
          <td style="min-width: 50px">
            <a href="https://t.me/mementobc" target="memento-telegram">
              <div class="telegram-image" :class="{ inverted: props.inverted }"></div>
            </a>
          </td>
          <td style="min-width: 5vw"></td>
        </tr>

        <tr style="height: 33%">
          <td style="min-width: 5vw"></td>
          <td style="width: 100%">
            <p style="font-size: 0.9rem; color: grey">&copy; 2025 Memento. All rights reserved.</p>
          </td>
          <td style="min-width: 120px">
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/services">
              Services
            </RouterLink>
          </td>
          <td style="min-width: 175px">
            <a
              class="_menuItem"
              :class="{ _inverted: props.inverted }"
              href="https://cdn.mementoblockchain.com/brand-assets/Memento_Brand_Assets.zip"
              target="_blank"
            >
              Brand Assets
            </a>
          </td>
          <td style="min-width: 175px">
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/zk-chain">
              ZKchain
            </RouterLink>
          </td>
          <td style="min-width: 200px">
            <a
              class="_menuItem"
              :class="{ _inverted: props.inverted }"
              href="http://vdp.mementoblockchain.com/"
              target="_blank"
            >
              Report Vulnerability
            </a>
          </td>
          <td style="width: 50px">
            <a href="https://sg.linkedin.com/company/memento-blockchain" target="Memento-linkedin">
              <div class="linkedin-image" :class="{ inverted: props.inverted }"></div>
            </a>
          </td>
          <td style="min-width: 50px">
            <a href="https://medium.com/@memento-bc" target="memento-medium">
              <div class="medium-image" :class="{ inverted: props.inverted }"></div>
            </a>
          </td>
          <td style="min-width: 5vw"></td>
        </tr>
      </tbody>
    </table>

    <table class="_mobileOnly" style="width: 100%; height: 100%; border-spacing: 0">
      <tbody>
        <tr>
          <td style="min-width: 1rem"></td>
          <td style="width: 50%">
            <span class="_menuItem _header" :class="{ _inverted: props.inverted }">Company</span>
          </td>
          <td style="width: 50%">
            <span class="_menuItem _header" :class="{ _inverted: props.inverted }">Products</span>
          </td>
          <td style="min-width: 1rem"></td>
        </tr>

        <tr>
          <td style="min-width: 1rem"></td>
          <td>
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/about-us">
              About Us
            </RouterLink>
          </td>
          <td>
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/dfm-protocol">
              DFM Protocol
            </RouterLink>
          </td>
          <td style="min-width: 1rem"></td>
        </tr>

        <tr>
          <td style="min-width: 1rem"></td>
          <td>
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/services">
              Services
            </RouterLink>
          </td>
          <td>
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/zk-chain">
              ZKchain
            </RouterLink>
          </td>
          <td style="min-width: 1rem"></td>
        </tr>

        <tr>
          <td style="min-width: 1rem"></td>
          <td>
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/dextf">
              $DEXTF
            </RouterLink>
          </td>
          <td style="width: 50%">
            <span class="_menuItem _header" :class="{ _inverted: props.inverted }">Contact</span>
          </td>
          <td style="min-width: 1rem"></td>
        </tr>

        <tr>
          <td style="min-width: 1rem"></td>
          <td>
            <a
              class="_menuItem"
              :class="{ _inverted: props.inverted }"
              href="https://cdn.mementoblockchain.com/brand-assets/Memento_Brand_Assets.zip"
              target="_blank"
            >
              Brand Assets
            </a>
          </td>
          <td>
            <RouterLink class="_menuItem" :class="{ _inverted: props.inverted }" to="/contacts">
              Email Us
            </RouterLink>
          </td>
          <td style="min-width: 1rem"></td>
        </tr>

        <tr>
          <td style="min-width: 1rem"></td>
          <td></td>
          <td></td>
          <td style="min-width: 1rem"></td>
        </tr>

        <tr>
          <td style="min-width: 1rem"></td>
          <td></td>
          <td></td>
          <td style="min-width: 1rem"></td>
        </tr>

        <tr>
          <td style="min-width: 1rem"></td>
          <td rowspan="2">
            <a href="/">
              <img
                class="ui image"
                :src="imageSrc"
                style="min-width: 150px; width: 150px; height: auto"
              />
            </a>
          </td>
          <td>
            <a href="https://twitter.com/Memento_Bc" target="memento-twitter">
              <div class="x-image" :class="{ inverted: props.inverted }" style="float: right"></div>
            </a>
            <a href="https://t.me/mementobc" target="memento-telegram">
              <div
                class="telegram-image"
                :class="{ inverted: props.inverted }"
                style="float: right; margin-right: 1rem"
              ></div>
            </a>
          </td>
          <td style="min-width: 1rem"></td>
        </tr>

        <tr>
          <td style="min-width: 1rem"></td>
          <td>
            <a href="https://medium.com/@memento-bc" target="Memento-medium">
              <div
                class="medium-image"
                :class="{ inverted: props.inverted }"
                style="float: right"
              ></div>
            </a>
            <a href="https://sg.linkedin.com/company/memento-blockchain" target="Memento-linkedin">
              <div
                class="linkedin-image"
                :class="{ inverted: props.inverted }"
                style="float: right; margin-right: 1rem"
              ></div>
            </a>
          </td>
          <td style="min-width: 1rem"></td>
        </tr>

        <tr>
          <td style="min-width: 1rem"></td>
          <td colspan="2">
            <p style="font-size: 0.9rem; color: grey">&copy; 2025 Memento. All rights reserved.</p>
          </td>
          <td style="min-width: 1rem"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.menu,
.menu.inverted {
  background-color: transparent !important;
}

table {
  transition-duration: 0.3s;
  transition-property: color, background-color, backdrop-filter;
}

._menuItem {
  color: #13151b;
  font-weight: 400;
  line-height: 1;
}

._menuItem._inverted {
  color: rgba(255, 255, 255, 0.8) !important;
}

._menuItem:not(._header):hover {
  color: #4fbaff !important;
}

._menuItem._header {
  font-weight: 600;
  color: white !important;
}

._menuTD {
  xbackground-color: rgba(255, 255, 255, 0.1) !important;
}

#productSubMenu,
#mobileSubMenu {
  background-color: transparent;
  border-radius: 4px;
}

._blurredGlass {
  backdrop-filter: blur(12px) !important;
  background: rgba(200, 200, 200, 0.3) !important;
  color: #13151b !important;
}

.inverted ._blurredGlass {
  color: white !important;
}

.x-image {
  width: 20px;
  height: 20px;
  background-image: url('/images/socials/x.png');
  background-size: cover;
}

.x-image.inverted {
  background-image: url('/images/socials/x-inverted.png');
}

.x-image:hover {
  background-image: url('/images/socials/x-hover.png');
}

.linkedin-image {
  width: 20px;
  height: 20px;
  background-image: url('/images/socials/linkedin.png');
  background-size: cover;
}

.linkedin-image.inverted {
  background-image: url('/images/socials/linkedin-inverted.png');
}

.linkedin-image:hover {
  background-image: url('/images/socials/linkedin-hover.png');
}

.medium-image {
  width: 20px;
  height: 20px;
  background-image: url('/images/socials/medium.png');
  background-size: cover;
}

.medium-image.inverted {
  background-image: url('/images/socials/medium-inverted.png');
}

.medium-image:hover {
  background-image: url('/images/socials/medium-hover.png');
}

.telegram-image {
  width: 20px;
  height: 20px;
  background-image: url('/images/socials/telegram.png');
  background-size: cover;
}

.telegram-image.inverted {
  background-image: url('/images/socials/telegram-inverted.png');
}

.telegram-image:hover {
  background-image: url('/images/socials/telegram-hover.png');
}

td {
  padding: 0.5rem;
  vertical-align: bottom;
}

table._mobileOnly > tbody > tr > td {
  padding: 0.5rem;
  vertical-align: middle;
}

@media screen and (min-width: 700px) {
  ._mobileOnly {
    display: none !important;
  }
}

@media screen and (max-width: 699px) {
  ._computerOnly {
    display: none !important;
  }
}

@media screen and (min-width: 1020px) {
  ._smallerScreensOnly {
    display: none !important;
  }
}

@media screen and (max-width: 1019px) {
  ._biggerScreenOnly {
    display: none !important;
  }

  #submenu {
    margin-right: 0;
  }

  ._menuTD {
    background-color: transparent !important;
  }
}
</style>

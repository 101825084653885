<script setup>
import { computed, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import AppNav from './components/AppNav.vue'
import Footer from './components/Footer.vue'

const state = reactive({
  mode: 0,
  headerBackgroundColor: 'rgba(255, 255, 255, 0.1)',
  border: 'none',
  blurInPx: 0,
})

const route = useRoute()

const currentView = computed(() => {
  return {
    desc: route.meta?.desc || route.name,
    name: route.name,
  }
})

const showBreadcrumb = false // computed(() => !['home', 'not-found'].includes(currentView.value.name))

const showContactFooter = computed(() => !['contacts'].includes(currentView.value.name))

const rightMenu = computed(() => {
  if (currentView.value.name === 'dfm-protocol') {
    return [
      {
        label: 'Wiki',
        href: 'https://domanifinance.notion.site/DOMANI-DAO-Wiki-eee68e859018473f9e624d711fcbd242',
        target: 'dfm-wiki',
      },
      { label: 'Docs', href: 'https://dfm-docs.mementoblockchain.com/', target: 'dfm-docs' },
      {
        label: 'dApp',
        href: 'https://dfm.mementoblockchain.com',
        target: 'dfm-dapp',
        type: 'button',
      },
    ]
  } else {
    return []
  }
})

onMounted(() => {})

function changeMode(mode) {
  state.mode = mode
  if (mode === 0) {
    state.headerBackgroundColor = 'rgba(255, 255, 255, 0)'
    state.blurInPx = 0
    state.border = 'none'
  } else if (mode === 1) {
    state.headerBackgroundColor = 'rgba(19, 19, 19, 0.5)'
    state.blurInPx = 4
    state.border = '1px solid rgba(153, 153, 153, 0.2)'
  } else if (mode === 2) {
    state.headerBackgroundColor = 'rgba(255, 255, 255, 1)'
    state.blurInPx = 0
    state.border = '1px solid rgba(153, 153, 153, 0.08)'
  }
}
</script>

<template>
  <div>
    <AppNav
      class="_header"
      :inverted="state.mode !== 2"
      :background-color="state.headerBackgroundColor"
      :border="state.border"
      :blur-in-px="state.blurInPx"
      :right-menu="rightMenu"
    />

    <div>
      <RouterView class="_rounterView" @changeMode="changeMode($event)" />

      <div class="_footer">
        <div
          v-show="showContactFooter"
          class="_contactFooter"
          style="background-image: url('./images/contact_footer_bg.webp')"
        >
          <div class="ui grid _dynamicTopMargin" style="height: 100%">
            <div class="computer only tablet only row">
              <div
                class="sixteen wide column right aligned bottom aligned _dynamicRightPadding"
                style="padding-bottom: 2rem !important"
              >
                <span class="ui large text" style="color: #effbff">
                  Have questions? Get in touch with us.
                </span>
              </div>
              <div class="sixteen wide column _dynamicRightPadding">
                <RouterLink class="ui button right floated" to="/contacts">Email us </RouterLink>
              </div>
            </div>
            <div class="mobile only row">
              <div
                class="fourteen wide column center aligned bottom aligned"
                style="padding-bottom: 2rem !important"
              >
                <span class="ui large text" style="color: #effbff">
                  Have questions? Get in touch with us.
                </span>
              </div>
              <div class="fourteen wide column center aligned">
                <RouterLink class="ui button" to="/contacts">Email us </RouterLink>
              </div>
            </div>
          </div>
        </div>

        <div class="_menuFooter">
          <Footer :inverted="true" :enable-hamburger="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
._header {
  position: fixed !important;
  z-index: 10;
  width: 100%;
  height: var(--header-height) !important;
  top: 0 !important;
  left: 0 !important;
  right: auto;
  bottom: auto;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 16px !important;
  padding-right: 16px !important;
  transition-duration: 0.3s;
  transition-property: color, background-color, backdrop-filter;
}

._rounterView {
  width: 100%;
  min-height: calc(100vh - var(--footer-height)) !important;
}

._contactFooter {
  height: var(--contact-footer-height) !important;
  background-color: #14252d;
  background-size: auto 100% !important;
  background-repeat: no-repeat !important;
  background-position: left 10vw center !important;
}

@media screen and (max-width: 800px) {
  ._contactFooter {
    background-size: 70% auto !important;
    background-position: right top !important;
  }
}

._menuFooter {
  height: var(--menu-footer-height) !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  display: flex;
  align-items: center;
}

._menuFooter > div {
  width: 100%;
}

/*
._menuFooter > .grid {
  margin-left: auto !important;
  margin-right: auto !important;
}

._menuFooter > .grid > .column {
  padding: 0 !important;
} */

._footer {
  background-color: #13151b;
  padding: 0;
}

._dynamicRightPadding {
  padding-right: 10vw !important;
}

@media screen and (max-width: 800px) {
  ._dynamicRightPadding {
    padding-right: 50% !important;
  }

  ._dynamicTopMargin {
    padding-top: 30% !important;
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
      meta: { title: `${import.meta.env.VITE_APP_TITLE} | Home`, desc: 'Home' },
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: () => import('@/views/AboutUsView.vue'),
      meta: { title: `${import.meta.env.VITE_APP_TITLE} | About Us`, desc: 'About Us' },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('@/views/ContactsView.vue'),
      meta: { title: `${import.meta.env.VITE_APP_TITLE} | Contacts`, desc: 'Contacts' },
    },
    {
      path: '/dextf',
      name: 'dextf',
      component: () => import('@/views/DextfView.vue'),
      meta: { title: `${import.meta.env.VITE_APP_TITLE} | DEXTF`, desc: 'DEXTF' },
    },
    {
      path: '/dfm-protocol',
      name: 'dfm-protocol',
      component: () => import('@/views/DfmProtocolView.vue'),
      meta: { title: `${import.meta.env.VITE_APP_TITLE} | DFM Protocol`, desc: 'DFM Protocol' },
    },
    {
      path: '/zk-chain',
      name: 'zk-chain',
      component: () => import('@/views/ZkChainView.vue'),
      meta: { title: `${import.meta.env.VITE_APP_TITLE} | Zkchain`, desc: 'Zkchain' },
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('@/views/ServicesView.vue'),
      meta: { title: `${import.meta.env.VITE_APP_TITLE} | Services`, desc: 'Services' },
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/views/ErrorView.vue'),
      meta: { title: `${import.meta.env.VITE_APP_TITLE} | Error`, desc: 'Error' },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/views/ErrorView.vue'),
      meta: { title: `${import.meta.env.VITE_APP_TITLE} | Error`, desc: 'Error' },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition
    // }
    // always scroll to top
    return { top: 0, behavior: 'smooth' }
  },
})

export default router
